/* !
 * index.js v1.0.0
 * Copyright, Connecty Inc.
 */
((JDI) => {
	const a = 'is-active';
	const v = 'is-visible';

	const mainVisHeight = () => {
		function setHeight(){
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		}
		setHeight();
		window.addEventListener('resize', setHeight);
	}

	// メインヴィジュアル
	const mainVisSwiper = () => {
		let slider1;
		window.addEventListener('load', () => {
			slider1  = new Swiper('.js-mainSlider', {
				slidesPerView: 1,
				spaceBetween: 0,
				loop: true,
				speed: 1000,
				autoplay: {
					delay: 5000,
					disableOnInteraction: false,
				},
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
			});

			// 停止・再生
			let stopBtns = document.querySelectorAll('.main-stopbtn');
			let pageNation = document.querySelector('.swiper-pagination');
			stopBtns.forEach((stopBtn) => {
				stopBtn.addEventListener('click', (e) => {
					let classList = e.currentTarget.classList;
					
					if (classList.contains('stop')) {
						classList.add('start');
						classList.remove('stop');
						pageNation.classList.add('stop');
						slider1.autoplay.stop();
					} else {
						classList.add('stop');
						classList.remove('start');
						pageNation.classList.remove('stop');
						slider1.autoplay.start();
					}
				});
			});
		});
		
	};
	
	// METAGROWTH 2026
	const metagrouthSwiper = () => {	
		let sliderItem = document.querySelectorAll('.js-metagrouthSlider .swiper-slide');
		// 連動スライド（ページネーション）
		sliderItem.forEach((ele, idx) => {
			let headTxt = ele.querySelector('.__head').innerHTML;
			let pageNation = document.querySelectorAll('.js-metagrouthSlider-thumb .swiper-slide')[idx];
			let num = document.createElement('p');
			let bar = document.createElement('div');
			let text = document.createElement('p');
			num.classList.add('__num');
			bar.classList.add('__bar');
			text.innerHTML = headTxt;
			num.innerHTML = '0' + Number(idx + 1);
			pageNation.appendChild(num);
			pageNation.appendChild(bar);
			pageNation.appendChild(text);
		});

		// トータル数とカレント表示
		if(document.querySelectorAll('.js-metagrouthSlider-total').length) {
			document.querySelector('.js-metagrouthSlider-total').textContent = '0'+ sliderItem.length;
		}

		let slider2;
		window.addEventListener('load', () => {
			var sliderThumbnail = new Swiper('.js-metagrouthSlider-thumb ', {
				loop: true,
				slidesPerView: 'auto',
				freeMode: true,
				spaceBetween: 20,
				watchSlidesVisibility: true,
				watchSlidesProgress: true,
			  });

			slider2  = new Swiper('.js-metagrouthSlider', {
				slidesPerView: 1,
				spaceBetween: 150, 
				loop: true,
				speed: 1000,
				autoplay: {
					delay: 5000,
					disableOnInteraction: false,
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				thumbs: {
					swiper: sliderThumbnail
				},
				on: {
					slideChange: function (e) { // アクティブなスライドが変更されると発生するイベント
						currentNumber = e.realIndex + 1
						document.querySelector('.js-metagrouthSlider-idx').textContent = '0'+ currentNumber;
					}
				}
			});

			// 停止・再生
			let stopBtns = document.querySelectorAll('.metagrouth-stopbtn');
			let thumb = document.querySelector('.js-metagrouthSlider-thumb');
			stopBtns.forEach((stopBtn) => {
				stopBtn.addEventListener('click', (e) => {
					let classList = e.currentTarget.classList;
					
					if (classList.contains('stop')) {
						classList.add('start');
						classList.remove('stop');
						thumb.classList.add('stop');
						slider2.autoplay.stop();
					} else {
						classList.add('stop');
						classList.remove('start');
						thumb.classList.remove('stop');
						slider2.autoplay.start();
					}
				});
			});
		});
		
	};

	// News
	const newsSwiper = () => {
		let slider3;
		let sliderItem = document.querySelectorAll('.js-newsSlider .swiper-slide');
		window.addEventListener('load', () => {
			slider3  = new Swiper('.js-newsSlider', {
				loop: false,
				slidesPerView: 1.3, // 1024px以下の場合
				spaceBetween: 20,
				speed: 1000,
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				breakpoints: {
					1024: { // 1024px以上の場合
						slidesPerView: 4,
						spaceBetween: 40,
					}
				}
			});

			// トータル数表示
			if(document.querySelectorAll('.js-newsSlider-total').length) {
				document.querySelector('.js-newsSlider-total').textContent = sliderItem.length;
			}

			var windowWidth = window.innerWidth;

			// ウィンドウの幅が1024px以上の場合
			if (windowWidth >= 1024) {
			    // 「top-news-slider-wrap」内の要素を取得
			    var topNewsSliderWrap = document.querySelector('.top-news-slider-wrap');

			    // 「top-news-slider-item」クラスの要素を取得
			    var topNewsItems = document.querySelectorAll('.top-news-slider-item');
			    
			    // 要素の数が4つ以下の場合
			    if (topNewsSliderWrap && topNewsItems.length <= 4) {
			        // 「swiper-pagination」要素を取得
			        var pagination = topNewsSliderWrap.querySelector('.swiper-pagination');
			        
			        // 「swiper-button-prev」要素を取得
			        var prevButton = topNewsSliderWrap.querySelector('.swiper-button-prev');
			        
			        // 「swiper-button-next」要素を取得
			        var nextButton = topNewsSliderWrap.querySelector('.swiper-button-next');
			        
			        // 要素が存在する場合、削除する
			        if (pagination) {
			            pagination.parentNode.removeChild(pagination);
			        }
			        
			        if (prevButton) {
			            prevButton.parentNode.removeChild(prevButton);
			        }
			        
			        if (nextButton) {
			            nextButton.parentNode.removeChild(nextButton);
			        }
			    }
			}

		});
	}

	// アニメーション
	const inViewAnimation = () => {
		// h2
		const targetH2 = document.querySelectorAll('.js-animation-h2');
		const callbackH2 = (entries, observer) => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					entry.target.classList.add(v);
				}
			});
		};
		const observerH2 = new IntersectionObserver(callbackH2);
		targetH2.forEach(ele => {
			observerH2.observe(ele);
		});

		// graduates
		const graduates1 = document.querySelectorAll('.js-graduates-t1');
		const callbackgraduates1 = (entries, observer) => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					entry.target.classList.add(v);
				}
			});
		};
		const observergraduates1 = new IntersectionObserver(callbackgraduates1);
		graduates1.forEach(ele => {
			observergraduates1.observe(ele);
		});

		const graduates2 = document.querySelectorAll('.js-graduates-t2');
		const callbackgraduates2 = (entries, observer) => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					entry.target.classList.add(v);
				}
			});
		};
		const observergraduates2 = new IntersectionObserver(callbackgraduates2);
		graduates2.forEach(ele => {
			observergraduates2.observe(ele);
		});

		// 画像
		const targetImg = document.querySelectorAll('.js-animation-block');
		const callbackImg = (entries, observer) => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					entry.target.classList.add(v);
				}
			});
		};
		const observerImg = new IntersectionObserver(callbackImg);
		targetImg.forEach(ele => {
			observerImg.observe(ele);
		});
		
	};


	// ==============================================
	mainVisHeight();
	mainVisSwiper();
	metagrouthSwiper();
	newsSwiper();
	inViewAnimation();

})(window.JDI);
